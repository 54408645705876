import { Component, ViewChild, Input } from '@angular/core';
import { IonTabs, Platform } from '@ionic/angular';

@Component({
  selector: 'app-tabbar',
  templateUrl: 'tabbar.component.html',
  styleUrls: ['tabbar.component.scss'],
})
export class TabbarComponent {
  @ViewChild('myTabs') tabs: IonTabs;
  activeTabName: any;
  opacityHome = 1;
  opacitySearch = 0.3;
  opacityLibrary = 0.3;
  barHeight="60px";
  barPadding="0px";
  constructor(  private platform: Platform,) {

    this.platform.ready().then(() => {
      if (this.platform.is('ios')) {
        this.barHeight="80px";
        this.barPadding="15px"
      }
    });
  }

  getSelectedTab() {
    this.activeTabName = this.tabs.getSelected();
    if (this.activeTabName == 'home') {
      this.opacityHome = 1;
      this.opacitySearch = 0.3;
      this.opacityLibrary = 0.3;
    }
    if (this.activeTabName == 'search') {
      this.opacityHome = 0.3;
      this.opacitySearch = 1;
      this.opacityLibrary = 0.3;
    }
    if (this.activeTabName == 'library') {
      this.opacityHome = 0.3;
      this.opacitySearch = 0.3;
      this.opacityLibrary = 1;
    }
  }
}
