import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from '@angular/core';
import { ClipService } from 'src/core/services/clip.service';
import { Clip } from 'src/core/types';
import { IonList } from '@ionic/angular';
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-clips',
  templateUrl: './clips.page.html',
  styleUrls: ['./clips.page.scss'],
})
export class ClipsPage implements OnInit {
  @ViewChild(IonList, { read: ElementRef }) list: ElementRef;
  channelId: number;
  clipID: number;
  clipList: Clip[] = [];
  loading: boolean = true;
  constructor(
    private clipService: ClipService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<ClipsPage>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.channelId = CryptoJS.AES.decrypt(this.data.id.trim(), '').toString(
      CryptoJS.enc.Utf8
    );
    this.clipID = this.data.clipId;
    this.clipService.getClipList(this.channelId, this.clipID).subscribe(
      res => {
        this.clipList = res;
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }
}
