import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { VideoDetails, Video } from '../types';

@Injectable({
  providedIn: 'root',
})
export class PlayVideoService {
  constructor(private http: HttpClient) {}
  getLog(Details) {
    return this.http.post(environment.URL + 'VidLog', Details).map(res => res);
  }

  public getCurrentTime(VidId) {
    let data = this.http
      .get<VideoDetails>(
        environment.URL +
          'GVWLD?VidId=' +
          VidId +
          '&UserId=' +
          localStorage.getItem('ID')
      )
      .toPromise();
    return data;
  }
  getVideoList(courselId) {
    let data = this.http
      .get<Video[]>(
        environment.URL +
          'GVFCBUCID?CourseId=' +
          courselId +
          '&UserId=' +
          localStorage.getItem('ID')
      )
      .toPromise();
    return data;
  }
}
