import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { SearchService } from 'src/core/services/search.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-channel-list-item',
  templateUrl: './channel-list-item.component.html',
  styleUrls: ['./channel-list-item.component.scss'],
})
export class ChannelListItemComponent implements OnInit {
  @Input() channelName: string;
  @Input() channelThumb: string;
  @Input() SubscriberCount: number;
  @Input() channelId: number;
  thumbURLChannel:string;
  insertSearchHistory = {};
  constructor(private route: Router, private searchService: SearchService) {}

  ngOnInit() {
    this.thumbURLChannel=environment.thumbURLChannel;
  }
  GoToChannelPage(channelId) {
    const textToConvert = channelId.toString();
    const conversionOutput = CryptoJS.AES.encrypt(
      textToConvert.trim(),
      ''
    ).toString();

    this.insertSearchHistory = {
      SearchId: 0,
      UserId: parseInt(localStorage.getItem('ID')),
      ChnlId: channelId,
      CrlId: 0,
      VidId: 0,
      CatId: 0,
    };
    this.searchService
      .setSearchHistory(this.insertSearchHistory)
      .subscribe(res => res);
    this.route.navigate(['/channel', conversionOutput]);
  }
}
