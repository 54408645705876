import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { CourseClipListComponent } from './components/course-clip-list/course-clip-list.component';
import { CourseCardComponent } from './components/course-card/course-card.component';
import { ClipCardComponent } from './components/clip-card/clip-card.component';
import { LibraryItemComponent } from './components/library-item/library-item.component';
import { RouterModule } from '@angular/router';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VideoListItemComponent } from './components/video-list-item/video-list-item.component';
import { ChannelListItemComponent } from './components/channel-list-item/channel-list-item.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { CourseListItemComponent } from './components/course-list-item/course-list-item.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import {MatButtonModule} from '@angular/material/button';
import { ScrollViewAllDirective } from 'src/core/directives/scroll-view-all.directive';
import { ScrollViewAllCatTextDirective } from 'src/core/directives/scroll-all-category-text.directive';
import { ScrollVanishDirective } from 'src/core/directives/scroll-vanish.directive';
import { ScrollTextDirective } from 'src/core/directives/scroll-text.directive';
import { ScrollViewAllTextDirective } from 'src/core/directives/scroll-view-all-text.directive';
import { ScrollViewLibraryDirective } from 'src/core/directives/scroll-view-library.directive';
import { ScrollTextLibraryDirective } from 'src/core/directives/scroll-text-library.directive';
import { NavigationBar } from '@ionic-native/navigation-bar/ngx';
import { ScrollFiterDirective } from 'src/core/directives/scroll-filter-div.directive';
import { LazyLoadImageModule  } from 'ng-lazyload-image'; // <-- import it
import { ScrollTextLibraryDirectiveChannel } from 'src/core/directives/scroll-text-librarychannel.directive';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    RouterModule,
    MatBottomSheetModule,
    MatProgressBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    LazyLoadImageModule
  ],
  declarations: [
    CourseClipListComponent,
    CourseCardComponent,
    ClipCardComponent,
    LibraryItemComponent,
    VideoListItemComponent,
    ChannelListItemComponent,
    LoaderComponent,
    SideBarComponent,
    CourseListItemComponent,
    ErrorMessageComponent,
    ScrollViewAllCatTextDirective,
    ScrollVanishDirective,
    ScrollTextDirective,
    ScrollViewAllDirective,
    ScrollViewAllTextDirective,
    ScrollViewLibraryDirective,
    ScrollTextLibraryDirective,
    ScrollFiterDirective,
    ScrollTextLibraryDirectiveChannel
  ],
  exports: [
    CourseClipListComponent,
    CourseCardComponent,
    ClipCardComponent,
    LibraryItemComponent,
    VideoListItemComponent,
    ChannelListItemComponent,
    LoaderComponent,
    SideBarComponent,
    CourseListItemComponent,
    ErrorMessageComponent,
    ScrollViewAllDirective,
    ScrollViewAllCatTextDirective,
    ScrollVanishDirective,
    ScrollTextDirective,
    ScrollViewAllTextDirective,
    ScrollViewLibraryDirective,
    ScrollTextLibraryDirective,
    ScrollFiterDirective,
    LazyLoadImageModule,
    ScrollTextLibraryDirectiveChannel
  ],
  providers: [StatusBar, SplashScreen, NavigationBar],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [ErrorMessageComponent],
})
export class SharedModule {}
