import { Component, OnInit, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-video-details',
  templateUrl: './video-details.component.html',
  styleUrls: ['./video-details.component.scss'],
})
export class VideoDetailsComponent implements OnInit {
  @Input() videoName: string;
  @Input() courseName: string;
  @Input() uploadVideoDate: string;
  @Input() loaderDetails:boolean;
  constructor(private bottomSheet: MatBottomSheet,public modalController: ModalController) {}

  ngOnInit() {}
  myBackButton() {
   this.modalController.dismiss({
    'dismissed': true
  });
  }
  socialMediaShare() {}
}
