import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Platform,
  MenuController,
  AlertController,
  ModalController,
} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationStart, RouterOutlet } from '@angular/router';
import { Location } from '@angular/common';
import { BottomSheetService } from './modules/home/bottom-sheet.service';
import { Subscription } from 'rxjs';
import { Network } from '@ionic-native/network/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Device } from '@ionic-native/device/ngx';
import { ToastController } from '@ionic/angular';
import { ErrorHandlingService } from 'src/core/services/error-handling.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  show: boolean = false;
  routerLink: string;
  isSidebar: boolean = false;
  userName: string;
  userMailId: string;
  PageURL: string = '';
  videoIsPlay: boolean = false;
  isOnline: boolean;
  subscription: Subscription;
  disconnectSubscription: Subscription;
  connectSubscription: Subscription;
  isConected: boolean = false;
  hasPermission: boolean;
  deviceDetails: string = '';
  count: number = 0;
  errorMessages = [];
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    private menu: MenuController,
    private _location: Location,
    public alertController: AlertController,
    private bottomSheetService: BottomSheetService,
    private network: Network,
    private androidPermissions: AndroidPermissions,
    private device: Device,
    public toastController: ToastController,
    private errorHandlingService: ErrorHandlingService,
    public modalController: ModalController
  ) {
    this.initializeApp();
  }
  backButtonEvent() {
    this.subscription = this.bottomSheetService.currentBottomSheet.subscribe(
      (isOpen) => {
        this.videoIsPlay = isOpen;
      }
    );

    document.addEventListener('backbutton', () => {
      if (this.router.url === '/home') {
        if (!this.videoIsPlay) {
          this.showExitConfirm();
        } else {
          this.modalController.dismiss();
        }
      } else if (this.router.url === '/') {
        navigator['app'].exitApp();
      } else if (this.router.url.includes('/course/')) {
        if (!this.videoIsPlay) {
          this._location.back();
        } else {
          this.modalController.dismiss();
        }
      } else if (this.router.url.includes('/library')) {
        if (!this.videoIsPlay) {
          this._location.back();
        } else {
          this.modalController.dismiss();
        }
      } else if (this.router.url.includes('/search')) {
        if (!this.videoIsPlay) {
          this._location.back();
        } else {
          this.modalController.dismiss();
        }
      } else {
        this._location.back();
      }
    });
  }
  ngOnInit() {
    this.errorHandlingService.getErrorMessages().subscribe((res) => {
      localStorage.setItem('ErrorMessages', JSON.stringify(res));
    });

    this.network.onConnect().subscribe(async () => {
      this.isConected = true;
      this.isOnline = true;
      this.modalController.dismiss();

      if (
        localStorage.getItem('offline') != null &&
        localStorage.getItem('offline') != '' &&
        localStorage.getItem('offline') != undefined
      ) {
        if (this.count == 0) {
          this.errorService(JSON.parse(localStorage.getItem('offline')));
        }
      }
    });

    this.network.onDisconnect().subscribe(async () => {
      this.isOnline = false;
    });

    this.backButtonEvent();
    this.userName = localStorage.getItem('Username');
    this.userMailId = localStorage.getItem('email');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.PageURL = event.url;
        if (
          event.url == '/' ||
          event.url.includes('/login') ||
          event.url.includes('/sign-up') ||
          event.url.includes(
            '/?ionic:mode=md&ionic:persistConfig=true&ionic:_forceStatusbarPadding=true'
          ) ||
          event.url.includes(
            '/?ionic:mode=ios&ionic:persistConfig=true&ionic:_forceStatusbarPadding=true'
          )
        ) {
          this.show = true;
        } else {
          this.show = false;
        }
        if (event.url.includes('home')) {
          this.isSidebar = true;
        } else {
          this.isSidebar = false;
        }
      }
    });
  }
  closeSidarBar() {
    this.menu.close();
  }
  logOut() {
    localStorage.setItem('TokenValue', '');
    localStorage.setItem('ID', '');
    localStorage.setItem('Username', '');
    localStorage.setItem('email', '');
    localStorage.setItem('password', '');
    this.router.navigateByUrl('');
  }
  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  initializeApp() {
    this.platform.ready().then((plat) => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      if (this.platform.is('android')) {
        this.androidPermissions
          .requestPermissions([
            this.androidPermissions.PERMISSION.READ_PHONE_STATE,
          ])
          .then(
            (result) => {
              if (result.hasPermission) {
              }
            },
            (err) => {}
          );
        let date = new Date();
        this.deviceDetails =
          this.device.platform +
          ',' +
          this.device.version +
          ',' +
          this.device.model +
          ',' +
          this.device.uuid;

        localStorage.setItem('deviceDetails', this.deviceDetails);
      }
    });
  }
  ionViewDidEnter() {}
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  showExitConfirm() {
    this.alertController
      .create({
        header: 'App termination',
        message: 'Do you want to close the app?',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Exit',
            handler: () => {
              navigator['app'].exitApp();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }
  errorService(errorObject) {
    this.count = this.count + 1;
    this.errorHandlingService
      .postErrorMessages(errorObject)
      .subscribe((res) => {
        localStorage.removeItem('offline');
      });
  }
}
