import { Component, OnInit, Input } from '@angular/core';
import { ChannelService } from 'src/core/services/channel.service';
import { subscriptionDetails } from 'src/core/types';
import { changeSubscriptionService } from '../../change-subscription.service';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-channel-details',
  templateUrl: './channel-details.component.html',
  styleUrls: ['./channel-details.component.scss'],
})
export class ChannelDetailsComponent implements OnInit {
  @Input() channelName: string;
  @Input() channelThumb: string;
  @Input() checkValue: number;
  @Input() channelID: number;
  @Input() loaderDetails: boolean;
  @Input() subscriberCount: number;
  subscriptionDetails: subscriptionDetails;
  thumbURLChannel:string;
  constructor(
    private channelService: ChannelService,
    private subscriptionService: changeSubscriptionService,
    private route: Router,
    public modalController: ModalController,
  ) {}

  ngOnInit() {
    this.thumbURLChannel=environment.thumbURLChannel;
  }
  Subscribe(channelID) {
    this.subscriptionDetails = {
      ChnlSubsId: 0,
      ChannelId: parseInt(channelID),
      UserId: parseInt(localStorage.getItem('ID')),
    };
    this.channelService
      .getSubscription(this.subscriptionDetails)
      .subscribe(res => {
        this.subscriptionService.toggleSubscription();
      });
    this.checkValue = 1;
  }
  GoToChannelPage(channelID) {
    this.modalController.dismiss();
    const textToConvert = channelID.toString();
    const conversionOutput = CryptoJS.AES.encrypt(
      textToConvert.trim(),
      ''
    ).toString();
    this.route.navigate(['/channel', conversionOutput]);
  }
}
