import { Injectable } from '@angular/core';
import { Clip } from '../types';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ClipService {
  private filterType = new BehaviorSubject(false);
  constructor(private http: HttpClient) {}
  getClipList(channelId,clipID) {
    return this.http
      .get<Clip[]>(
        environment.URL + 'GCLPFCHNLID?ChannelId=' + channelId + '&ClipId='+clipID
      )
      .map(res => res);
  }

  getTabValue(): Observable<boolean> {
    debugger;
    return this.filterType.asObservable();
  }
}
