import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class changeSubscriptionService {
  isSubscribe: number = 1;
  videoId: number = 0;
  @Output() Subscription: EventEmitter<number> = new EventEmitter();
  @Output() playVideoService: EventEmitter<number> = new EventEmitter();

  toggleSubscription() {
    this.isSubscribe = this.isSubscribe;
    this.Subscription.emit(this.isSubscribe);
  }
  PlayVideo(ID) {
    this.videoId = ID;
    this.playVideoService.emit(this.videoId);
  }
}
