import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { RestApiService } from 'src/core/services/home.service';
import { Course, Clip } from 'src/core/types';
import { IonInfiniteScroll } from '@ionic/angular';
import { HomePaginationService } from 'src/app/modules/home/home-pagination.service';

@Component({
  selector: 'app-course-clip-list',
  templateUrl: './course-clip-list.component.html',
  styleUrls: ['./course-clip-list.component.scss'],
})
export class CourseClipListComponent implements OnInit {
  @Input() courseList: Course[];
  @Input() clipList: Clip[];
  @Input() headerText: string;
  @Input() loading: boolean;
  @Input() loaderClip: boolean;
  @Input() loaderCourse: boolean;
  @Input() fromPage:boolean;
  page: number = 0;
  color = 'accent';
  mode = 'Indeterminate';
  @ViewChild(IonInfiniteScroll)
  infiniteScroll: IonInfiniteScroll;

  constructor(
    public restApiService: RestApiService,
    private homePaginationService: HomePaginationService
  ) {}
  ngOnInit() {
    this.loadeMoreData(4);
  }
  loadData(event) {
    setTimeout(() => {
      event.target.complete();
      this.loadeMoreData(4);
    }, 500);
  }
  loadeMoreData(length) {
    this.page = this.page + 1;
    if (this.fromPage) {
      this.homePaginationService.toggleHomePagination(this.page, length);
    }
   
  }
}
