import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from 'src/environments/environment';
import { Course, ContinueWatching, Channel, Clip } from '../types';

@Injectable({
  providedIn: 'root',
})
export class RestApiService {
  constructor(private http: HttpClient) {}
  getClipList(PageNo, totalPages) {
    return this.http
      .get<Clip[]>(
        environment.URL +
          'GCLPBCNTENRL?UserId=' +
          localStorage.getItem('ID') +
          '&PageNo=' +
          PageNo +
          '&PageSize=' +
          totalPages +
          ''
      )
      .map((res) => res);
  }

  getCourseList(PageNo, PageSize) {
    return this.http
      .get<Course[]>(
        environment.URL +
          'GCRBCNTENRL?UserId=' +
          localStorage.getItem('ID') +
          '&PageNo=' +
          PageNo +
          '&PageSize=' +
          PageSize +
          ''
      )
      .map((res) => res);
  }
  getChannelList() {
    return this.http
      .get<Channel[]>(
        environment.URL +
          'GCHNL?UserId=' +
          localStorage.getItem('ID') +
          '&FilterType=3'
      )
      .map((res) => res);
  }
  getHeaderCourseList() {
    const userid = localStorage.getItem('ID');
    return this.http
      .get<ContinueWatching[]>(environment.URL + 'GMCBUID?userid=' + userid)
      .map((res) => res);
  }
}
