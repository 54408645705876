import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-video-list-item',
  templateUrl: './video-list-item.component.html',
  styleUrls: ['./video-list-item.component.scss'],
})
export class VideoListItemComponent implements OnInit {
  @Input() videoTitle: string;
  @Input() currentTime: number;
  @Input() videoDuration: number;
  @Input() videoId: number;
  @Input() count: number;
  @Input() videoIdPlay: number;
  @Input() channelId: number;
  @Input() courseId: number;
  @Input() IsCrEnrl: number;
  @Input() dark: boolean = false;
  @Output() playVideoEvent = new EventEmitter();
  constructor() {}

  ngOnInit() {}
  PlayVideo() {
    this.playVideoEvent.emit();
  }
}
