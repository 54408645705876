import { Component, OnInit, Input } from '@angular/core';
import { Clip } from 'src/core/types';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ClipsPage } from 'src/app/modules/clip/page/clips.page';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-clip-card',
  templateUrl: './clip-card.component.html',
  styleUrls: ['./clip-card.component.scss'],
})
export class ClipCardComponent implements OnInit {
  @Input() clips: Clip;
  constructor(private route: Router, public dialog: MatDialog) {}

  ngOnInit() {}
  playClip(channelId, ClipID) {
    const textToConvert = channelId.toString();
    const conversionOutput = CryptoJS.AES.encrypt(
      textToConvert.trim(),
      ''
    ).toString();
    this.dialog.open(ClipsPage, {
      width: '100%',
      data: { id: conversionOutput, clipId: ClipID },
    });
  }
}
