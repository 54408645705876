import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ErrorHandlingService } from '../services/error-handling.service';
import { AlertMessage } from 'src/app/shared/alert-controller/alert-message';
import { ToastController } from '@ionic/angular';
import { Injectable } from "@angular/core";

@Injectable()
export class ErrorIntercept implements HttpInterceptor {
  constructor(
    private errorHandlingService: ErrorHandlingService,
    public toastController: ToastController
  ) {}
  isErrorCheck: boolean = false;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        let errorMessageForPost = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessageForPost = `Error: ${error.error.message}`;
          errorMessage = error.message;
        } else {
          // server-side error
          errorMessageForPost = `Error Status: ${error.status}\nMessage: ${error.message}`;
          errorMessage = error.message;
        }
        if (!this.isErrorCheck) {
          let errorObject = {};
          let date = new Date();
          if (errorMessage.includes('0 Unknown Error')) {
            errorObject = {
              MsgId: 3,
              ErrorOrigin: 1,
              ErrorShortMsg: 'Server Down',
              ErrorDetails: errorMessageForPost,
              ClientAccessDetails: localStorage.getItem('deviceDetails'),
              ErrorTimeLocal: date,
              LogFile: 'LoginFile',
            };
            this.isErrorCheck = true;

            this.displayErrorMessage('Server Error ');
            this.errorService(errorObject);
          } else if (errorMessage.includes('404 OK')) {
            errorObject = {
              MsgId: 4,
              ErrorOrigin: 1,
              ErrorShortMsg: 'URL not found',
              ErrorDetails: errorMessageForPost,
              ClientAccessDetails: localStorage.getItem('deviceDetails'),
              ErrorTimeLocal: date,
              LogFile: 'LoginFile',
            };
            this.isErrorCheck = true;

            this.displayErrorMessage('Page does not exist');
            this.errorService(errorObject);
          } else {
            errorObject = {
              MsgId: 5,
              ErrorOrigin: 1,
              ErrorShortMsg: 'Other Errors',
              ErrorDetails: 'New error is found',
              ClientAccessDetails: localStorage.getItem('deviceDetails'),
              ErrorTimeLocal: date,
              LogFile: 'LoginFile',
            };
            this.isErrorCheck = true;
            this.displayErrorMessage('Unknown Error');
            this.errorService(errorObject);
          }
        }
        return throwError(errorMessage);
      })
    );
  }
  errorService(errorObject) {
    this.errorHandlingService
      .postErrorMessages(errorObject)
      .subscribe((res) => {});
  }
  async displayErrorMessage(message) {
    const toast = await this.toastController.create({
      message: message,
      position: 'top',
      duration: 5000,
      color: 'danger',
    });

    await toast.present().then((res) => (this.isErrorCheck = true));

    toast.onDidDismiss().then((data) => {
      this.isErrorCheck = false;
    });
  }
}
