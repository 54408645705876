import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { ClipRoutingModule } from './clip-routing.module';
import { ClipsPage } from './page/clips.page';
import { ClipHeaderComponent } from './components/clip-header/clip-header.component';
import { ClipDetailsComponent } from './components/clip-details/clip-details.component';
import { ClipVideoTagComponent } from './components/clip-video-tag/clip-video-tag.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
@NgModule({
  declarations: [
    ClipsPage,
    ClipHeaderComponent,
    ClipDetailsComponent,
    ClipVideoTagComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    SharedModule,
    ClipRoutingModule,
    MatListModule,
    MatSliderModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],entryComponents:[ClipsPage]
})
export class ClipModule {}
