import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { DomController } from '@ionic/angular';

@Directive({
  selector: '[myScrollTextLibrary]',
})
export class ScrollTextLibraryDirective implements OnInit {
  @Input('myScrollTextLibrary') scrollAreaTextLibrary;

  private hidden: boolean = false;
  private triggerDistance: number = 20;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private domCtrl: DomController
  ) {}

  ngOnInit() {
    this.initStyles();

    this.scrollAreaTextLibrary.ionScroll.subscribe((scrollEvent) => {
      let delta = scrollEvent.detail.deltaY;

      if (scrollEvent.detail.currentY === 0 && this.hidden) {
        this.showText();
      } else if (!this.hidden && delta > this.triggerDistance) {
        this.hideText();
      } else if (this.hidden && delta < -this.triggerDistance) {
        this.showText();
      }
    });
  }

  initStyles() {
    this.domCtrl.write(() => {
      this.renderer.setStyle(
        this.element.nativeElement,
        'transition',
        '0.2s linear'
      );
      this.renderer.setStyle(this.element.nativeElement, 'height', 'auto');
    });
  }

  hideText() {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, 'min-height', 'auto');
      this.renderer.setStyle(this.element.nativeElement, 'height', 'auto');
      this.renderer.setStyle(this.element.nativeElement, 'opacity', '10');
      this.renderer.setStyle(this.element.nativeElement, 'font-size', '22px');
      this.renderer.setStyle(this.element.nativeElement, 'padding', '0');
      this.renderer.setStyle(
        this.element.nativeElement,
        'transform',
        'translate(30%, 0%)'
      );
    });

    this.hidden = true;
  }

  showText() {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, 'height', 'auto');
      this.renderer.removeStyle(this.element.nativeElement, 'opacity');
      this.renderer.setStyle(this.element.nativeElement, 'font-size', '32px');
      this.renderer.removeStyle(this.element.nativeElement, 'min-height');
      this.renderer.removeStyle(this.element.nativeElement, 'padding');
      this.renderer.removeStyle(this.element.nativeElement, 'transform');
      this.renderer.setStyle(this.element.nativeElement, 'text-align', 'start');
    });

    this.hidden = false;
  }
}
