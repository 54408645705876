import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  constructor(private http: HttpClient) {}
  getErrorMessages() {
    let headers: HttpHeaders = new HttpHeaders({
      Token: 'tokenforvideoapp',
    });
    return this.http.get(environment.URL + 'GERRMSG').map((res) => res);
  }
  postErrorMessages(errorObject) {
    return this.http
      .post(environment.URL+ 'SaveExceptionTrace', errorObject)
      .map((res) => res);
  }
}
