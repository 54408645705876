import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-clip-details',
  templateUrl: './clip-details.component.html',
  styleUrls: ['./clip-details.component.scss'],
})
export class ClipDetailsComponent implements OnInit {
  @Input() clipTitle;
  @Input() clipDate;
  constructor() {}

  ngOnInit() {}
}
