import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
  HostListener,
} from '@angular/core';
import { PlayVideoService } from 'src/core/services/play-video.service';
import { Location } from '@angular/common';
import { VideoDetails, logDetails, Video } from 'src/core/types';
import { NavParams, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
@Component({
  selector: 'app-play-video',
  templateUrl: './play-video.page.html',
  styleUrls: ['./play-video.page.scss'],
})
export class PlayVideoPage implements OnInit {
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  @HostListener('document:fullscreenchange', []) fullScreen() {}
  currentTime: number;
  URL: string;
  channelID: number;
  videoId: number;
  courseID: number;
  categoryID: number;
  details: logDetails;
  videoName: string;
  courseName: string;
  channelName: string;
  channelThumb: string;
  videoLongDescription: string;
  duration: number;
  videoDetails: VideoDetails;
  preTime: number;
  resumeTime: number;
  videoList: Video[] = [];
  checkValue: number;
  Id: number;
  uploadVideoDate: string;
  subscriberCount: number;
  loaderDetails: boolean = true;
  loaderVideoList: boolean = true;

  //from video tag
  tagLoad: boolean = false;
  isBrowser;
  detectBrowser: boolean = false;
  isPlatformCheck: boolean = false;
  VideoID: number;
  myVar: number;
  autoHide: boolean = false;
  constructor(
    private playVideoService: PlayVideoService,
    private location: Location,
    private platform: Platform,
    public screenOrientation: ScreenOrientation,
    private statusBar: StatusBar,
    private navParams: NavParams
  ) {
    this.platform.backButton.subscribe(async () => {});
    this.videoId = this.navParams.get('id');
  }
  onTimeUpdate(data) {
    this.currentTime = data.target.currentTime;
  }
  HideVideo(event) {
    this.autoHide = true;
    if (document.fullscreen == true) {
      this.statusBar.hide();
      this.screenOrientation.lock(
        this.screenOrientation.ORIENTATIONS.LANDSCAPE
      );
    } else if (document.fullscreen == false) {
      this.statusBar.show();
      this.autoHide = false;
      this.screenOrientation.unlock();
    } else {
      this.screenOrientation.unlock();
      this.statusBar.show();
      this.autoHide = false;
    }
  }
  ngOnInit() {
    this.platform.ready().then(() => {
      if (this.platform.is('ios')) {
        this.isPlatformCheck = true;
        this.detectBrowser = true;
      }
    });
    this.isBrowser = navigator.userAgent.toLowerCase();
    if (this.isBrowser.indexOf('safari') != -1) {
      if (this.isBrowser.indexOf('chrome') > -1) {
        // Chrome
      } else {
        // Safari
        this.detectBrowser = true;
      }
    }

    this.getVideoDetails();
  }

  getVideoDetails() {
    this.playVideoService.getCurrentTime(this.videoId).then(
      (result) => {
        this.videoDetails = result;
        this.getCurrentTime(result);
        this.courseID = this.videoDetails[0].CourseId;
        this.videoName = this.videoDetails[0].VideoTitle;
        this.courseName = this.videoDetails[0].CourseName;
        this.channelName = this.videoDetails[0].ChannelName;
        this.channelThumb = this.videoDetails[0].ChnlThumbnail;
        this.videoLongDescription = this.videoDetails[0].Longdescription;
        this.checkValue = this.videoDetails[0].IsChnlSubs;
        this.channelID = this.videoDetails[0].ChannelId;
        this.Id = this.videoDetails[0].VideoId;
        this.uploadVideoDate = this.videoDetails[0].UploadVidDate;
        this.subscriberCount = this.videoDetails[0].ChnlSubscbdCount;
        this.loaderDetails = false;
        this.getVideoList();
      },
      (error) => {
        this.loaderDetails = false;
        this.tagLoad = true;
        this.getVideoList();
      }
    );
  }
  getVideoList() {
    this.playVideoService.getVideoList(this.courseID).then(
      (res) => {
        this.videoList = res;
        this.loaderVideoList = false;
      },
      (error) => {
        this.loaderVideoList = false;
      }
    );
  }
  getCurrentTime(res) {
    if (res == '') {
      this.preTime = 0;
      this.playVideoFromResume(this.preTime);
    } else {
      this.videoDetails = res;
      this.preTime = this.videoDetails[0].CurrentTime;
      this.VideoID = this.videoDetails[0].VideoId;
      this.channelID = this.videoDetails[0].ChannelId;
      this.courseID = this.videoDetails[0].CourseId;
      this.categoryID = this.videoDetails[0].CatId;
      if (this.isPlatformCheck) {
        this.URL =
          environment.videoURL +
          this.videoDetails[0].VideoURL +
          this.videoDetails[0].Ext;
        this.tagLoad = true;
      } else {
        this.URL =
          environment.streamURL +
          this.videoDetails[0].VideoURL +
          this.videoDetails[0].Ext;
        this.tagLoad = true;
      }

      if (!this.detectBrowser) {
        this.videoplayer.nativeElement.load();
      }
      this.preTime = Math.round(this.preTime);
      this.playVideoFromResume(this.preTime);
    }
  }
  playVideoFromResume(resumeTime) {
    this.resumeTime = resumeTime;
    if (!this.detectBrowser) {
      if (resumeTime != 0) {
        this.videoplayer.nativeElement.currentTime = resumeTime;
      } else {
        this.videoplayer.nativeElement.currentTime = 0;
      }
      this.videoplayer.nativeElement.load();
    }

    var ct_tm = 0;

    this.myVar = window.setInterval(() => {
      this.duration = this.currentTime;
      this.details = {
        UserID: parseInt(localStorage.getItem('ID')),
        VideoID: this.VideoID,
        ChannelID: this.channelID,
        CourseID: this.courseID,
        CategoryID: this.categoryID,
        CurrentTime: this.duration,
      };
      if (ct_tm != this.duration) {
        setTimeout(() => {
          this.playVideoService.getLog(this.details).subscribe((res) => res);
        }, 1000);

        ct_tm = this.duration;
      }
    }, 2000);
  }
}
