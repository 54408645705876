import { Component, OnInit, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';
import { Network } from '@ionic-native/network/ngx';
import { MatDialog } from '@angular/material/dialog';
import { AlertMessage } from 'src/app/shared/alert-controller/alert-message';
import { Router, NavigationStart } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  isConected: boolean = true;
  pageURL: string = '';
  errorObject = {};
  constructor(
    private network: Network,
    private dialog: MatDialog,
    private alertMessage: AlertMessage,
    public router: Router,
    public modalController: ModalController,
    public toastController: ToastController,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.network.onConnect().subscribe(async () => {
      this.isConected = true;
      this.modalController.dismiss();

    });

    this.network.onDisconnect().subscribe(async () => {
      this.isConected = false;
    });
    if (this.isConected) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.pageURL = event.url;
        }
      });
      if (
        localStorage.getItem('TokenValue') == null ||
        localStorage.getItem('TokenValue') == ''
      ) {
        request = request.clone({
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
            Accept: 'application/json',
            'content-type': 'application/json',
            Token: 'tokenforvideoapp',
          }),
        });
        return next.handle(request);
      } else {
        request = request.clone({
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
            Accept: 'application/json',
            'content-type': 'application/json',
            Token: localStorage.getItem('TokenValue'),
          }),
        });
        return next.handle(request);
      }
    } else {
     this.displayErrorMessage()
      let date = new Date();
      this.errorObject = {
        MsgId: 1,
        ErrorOrigin: 1,
        ErrorShortMsg: 'offline',
        ErrorDetails: 'Disconnected',
        ClientAccessDetails: localStorage.getItem('deviceDetails'),
        ErrorTimeLocal: date,
        LogFile: 'LoginFile',
      };
      localStorage.setItem('offline', JSON.stringify(this.errorObject));
    }
  }
  async displayErrorMessage()
  {
    const toast = await this.toastController.create({
      message: 'No Internet Connection',
      position: 'top',
      duration: 5000,
      color: 'danger',
    });
   
    await toast.present();
  }
}
