import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomePaginationService {
  private homePaginationValue: BehaviorSubject<any> = new BehaviorSubject({
    nextPage: 0,
    totalPages: 4,
  });
  currentPaginationValue = this.homePaginationValue.asObservable();

  toggleHomePagination(
    nextPage: number,
    totalPages: number,
  ) {
    this.homePaginationValue.next({
      nextPage: nextPage,
      totalPages: totalPages,
    });
  }
}