import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category, Course, Clip, channelDetails, Channel } from '../types';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  constructor(private http: HttpClient) {}

  getSubscription(details) {
    return this.http
      .post(environment.URL + 'SaveChnlSubs?', details)
      .map(res => res);
  }
  getUnSubscription(details) {
    return this.http
      .post(environment.URL + 'UpdateSubscription?', details)
      .map(res => res);
  }

  getCategoryList(channelId) {
    return this.http
      .get<Category[]>(
        environment.URL +
          'GCCBCHNLID?ChannelId=' +
          channelId +
          '&FilterType=1' +
          '&ViewAll=0'
      )
      .map(res => res);
  }
  getChannelCourseList(channelId) {
    return this.http
      .get<Course[]>(environment.URL + 'GCBERCNT?ChannelId=' + channelId)
      .map(res => res);
  }
  getChannelClipList(channelId) {
    return this.http
      .get<Clip[]>(
        environment.URL +
          'GCLPBERCNT?ChannelId=' +
          channelId +
          '&UserId=' +
          localStorage.getItem('ID')
      )
      .map(res => res);
  }
  getChannelDetails(channelId) {
    return this.http
      .get<channelDetails>(
        environment.URL +
          'GCDBUIDCHNLID?ChannelId=' +
          channelId +
          '&UserId=' +
          localStorage.getItem('ID')
      )
      .map(res => res);
  }
  getChannelList(filterType) {
    return this.http
      .get<Channel[]>(
        environment.URL +
          'GCHNL?UserId=' +
          localStorage.getItem('ID') +
          '&FilterType=' +
          filterType
      )
      .map(res => res);
  }
}
