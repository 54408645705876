import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { PlayVideoService } from 'src/core/services/play-video.service';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { logDetails, VideoDetails, Video } from 'src/core/types';
import { Platform } from '@ionic/angular';
@Component({
  selector: 'app-video-tag',
  templateUrl: './video-tag.component.html',
  styleUrls: ['./video-tag.component.scss'],
})
export class VideoTagComponent implements OnInit {
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  videoElement: HTMLVideoElement;
  currentTime: number;
  URL: string;
  VideoID: number;
  videoId: number;
  channelID: number;
  courseID: number;
  categoryID: number;
  details: logDetails;
  duration: number;
  myVar: number;
  videoDetails: VideoDetails;
  preTime: number;
  resumeTime: number;
  blobURL: any;
  isChunkloaded: boolean = false;
  tagLoad: boolean = false;
  isBrowser;
  detectBrowser: boolean = false;
  isPlatformCheck: boolean = false;
  constructor(
    private playVideoService: PlayVideoService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheet: MatBottomSheet,
    private platform: Platform
  ) {
    this.videoId = data.id;
  }

  ngOnInit() {
    this.platform.ready().then(() => {
      if (this.platform.is('ios')) {
        this.isPlatformCheck = true;
        this.detectBrowser = true;
      }
    });
    this.isBrowser = navigator.userAgent.toLowerCase();
    if (this.isBrowser.indexOf('safari') != -1) {
      if (this.isBrowser.indexOf('chrome') > -1) {
        // Chrome
      } else {
        // Safari
        this.detectBrowser = true;
      }
    }
    this.playVideoService.getCurrentTime(this.videoId).then(
      (result) => {
        this.getCurrentTime(result);
        this.tagLoad = true;
      },
      (error) => {}
    );
  }
  onTimeUpdate(data) {
    this.currentTime = data.target.currentTime;
  }
  getCurrentTime(res) {
    if (res == '') {
      this.preTime = 0;
      this.playVideoFromResume(this.preTime);
    } else {
      this.videoDetails = res;
      this.preTime = this.videoDetails[0].CurrentTime;
      this.VideoID = this.videoDetails[0].VideoId;
      this.channelID = this.videoDetails[0].ChannelId;
      this.courseID = this.videoDetails[0].CourseId;
      this.categoryID = this.videoDetails[0].CatId;
      if (this.isPlatformCheck) {
        this.URL =
          'https://stream.elbasoft.com/api/PV?DocUrl=' +
          this.videoDetails[0].VideoURL +
          this.videoDetails[0].Ext;
      } else {
        this.URL =
          'https://stream.elbasoft.com/api/PV?DocUrl=' +
          this.videoDetails[0].VideoURL +
          this.videoDetails[0].Ext;
      }

      if (!this.detectBrowser) {
        this.videoplayer.nativeElement.load();
      }
      this.preTime = Math.round(this.preTime);
      this.playVideoFromResume(this.preTime);
    }
  }
  async playVideoFromResume(resumeTime) {
    this.resumeTime = resumeTime;
    if (!this.detectBrowser) {
      if (resumeTime != 0) {
        this.videoplayer.nativeElement.currentTime = resumeTime;
      } else {
        this.videoplayer.nativeElement.currentTime = 0;
      }
      this.videoplayer.nativeElement.load();
    }

    var ct_tm = 0;

    this.myVar = window.setInterval(() => {
      this.duration = this.currentTime;
      this.details = {
        UserID: parseInt(localStorage.getItem('ID')),
        VideoID: this.VideoID,
        ChannelID: this.channelID,
        CourseID: this.courseID,
        CategoryID: this.categoryID,
        CurrentTime: this.duration,
      };
      if (ct_tm != this.duration) {
        setTimeout(() => {
          this.playVideoService.getLog(this.details).subscribe((res) => res);
        }, 1000);

        ct_tm = this.duration;
      }
    }, 2000);
  }
  ionViewWillLeave() {
    clearInterval(this.myVar);
  }
  myBackButton() {
    this.bottomSheet.dismiss();
  }
}
