import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-clip-header',
  templateUrl: './clip-header.component.html',
  styleUrls: ['./clip-header.component.scss'],
})
export class ClipHeaderComponent implements OnInit {
  @Input() channelName;
  @Input() channelThumbNail;
  constructor(private location: Location, public dialog: MatDialog) {}

  ngOnInit() {}
  close() {
    this.dialog.closeAll();
  }
}