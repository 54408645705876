import { Component, OnInit, Input } from '@angular/core';
import { PlayVideoService } from 'src/core/services/play-video.service';
import { Video, VideoDetails } from 'src/core/types';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PlayVideoPage } from '../../play-video/play-video.page';
import { BottomSheetService } from 'src/app/modules/home/bottom-sheet.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss'],
})
export class VideoListComponent implements OnInit {
  @Input() videoLongDescription: string;
  @Input() checkValue: number;
  @Input() courseID: number;
  @Input() videoList: Video[];
  @Input() videoId: number;
  @Input() loaderVideoList: boolean;
  isSelected: number = 0;
  isModalOpen: boolean = false;
  constructor(
    private bottomSheet: MatBottomSheet,
    private bottomSheetService: BottomSheetService,
    public modalController: ModalController
  ) {}

  ngOnInit() {}
  Outline() {
    this.isSelected = 1;
  }
  Transcript() {
    this.isSelected = 0;
  }
  async PlayVideo(VideoId) {
    if (this.isModalOpen) {
      this.openModal(VideoId);
    } else {
      this.modalController.dismiss().then(async (res) => {
        this.openModal(VideoId);
      });
    }
  }
  async openModal(VideoId) {
    const modal = await this.modalController.create({
      component: PlayVideoPage,
      componentProps: { id: VideoId },
       animated:true,

      backdropDismiss: true,
    });

    modal.onDidDismiss().then((dataReturned) => {
      this.bottomSheetService.toggleBottomSheet(false);
      this.isModalOpen = false;
    });

    return await modal.present().then((modal) => {
      this.bottomSheetService.toggleBottomSheet(true);
      this.isModalOpen = true;
    });
  }
}
