import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { searchHistory, searchList } from '../types';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private http: HttpClient) {}
  getSearchHistory() {
    let UserId = localStorage.getItem('ID');

    return this.http
      .get<searchHistory[]>(environment.URL + 'SHUWID?UserId=' + UserId)
      .map((res) => res);
  }
  getSearchList(searchTerm, activeTab) {
    return this.http
      .get<searchList[]>(
        environment.URL +
          'SAE?searchtext=' +
          searchTerm +
          '&EntType=' +
          activeTab +
          '&UserId=' +
          localStorage.getItem('ID')
      )
      .map((res) => res);
  }
  setSearchHistory(Details) {
    let UserId = localStorage.getItem('ID');

    return this.http
      .post(environment.URL + 'SaveSearchHistByUserId', Details)
      .map((res) => res);
  }
}
