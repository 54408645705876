// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  URL: 'https://demo.api.circlesnow.com/ProductRESTService.svc/',
  thumbURLChannel: 'https://demo.admin.circlesnow.com/Uploads/Channels/',
  thumbURLCourse: 'https://demo.admin.circlesnow.com/Uploads/Courses/ar600/',
  thumbURLCourse_1: 'https://demo.admin.circlesnow.com/Uploads/Courses/',
  videoURL: 'https://demo.admin.circlesnow.com/Uploads/Videos/',
  streamURL: 'https://demo.stream.circlesnow.com/api/PV?DocUrl=',
  clipURL: 'https://demo.admin.circlesnow.com/Uploads/clips/',
  paymentURL: 'https://demo.pay.circlesnow.com/transact.aspx?',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
