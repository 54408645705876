import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  userName: string;
  userMailId: string;
  @Input() drawer:any;
   constructor(public router: Router) {}

  ngOnInit() {
    this.userName = localStorage.getItem('Username');
    this.userMailId = localStorage.getItem('email');
    this.userName =
      this.userName.length > 1
        ? this.userName[0].toUpperCase() + this.userName.substr(1).toLowerCase()
        : this.userName.toUpperCase();
  }
  logOut() {
    localStorage.setItem('TokenValue', '');
    localStorage.setItem('ID', '');
    localStorage.setItem('Username', '');
    localStorage.setItem('email', '');
    localStorage.setItem('password', '');
    this.router.navigateByUrl('');
  }
 
}
