import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AuthGuard } from 'src/core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'library',
    loadChildren: () =>
      import('./modules/library/library.module').then((m) => m.LibraryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'channel/:channelId',
    loadChildren: () =>
      import('./modules/channel/channel.module').then((m) => m.ChannelModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'course/:CourseId',
    loadChildren: () =>
      import('./modules/course/course.module').then((m) => m.CourseModule),
  },

  {
    path: 'video/:videoId',
    loadChildren: () =>
      import('./modules/play-video/play-video.module').then(
        (m) => m.PlayVideoModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'category/:channelId',
    loadChildren: () =>
      import(
        './modules/category/pages/view-all-categories/view-all-categories.module'
      ).then((m) => m.ViewAllCategoriesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'category/:channelId/:categoryId',
    loadChildren: () =>
      import('./modules/category/category.module').then(
        (m) => m.CategoryPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'clip/:channelId',
    loadChildren: () =>
      import('./modules/clip/clip.module').then((m) => m.ClipModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'channel-list',
    loadChildren: () =>
      import(
        './modules/channel/pages/view-all-channel/view-all-channel.module'
      ).then((m) => m.ViewAllChannelPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    IonicModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
