import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PlayVideoPage } from 'src/app/modules/play-video/play-video/play-video.page';
import { BottomSheetService } from 'src/app/modules/home/bottom-sheet.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { IonRouterOutlet, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-library-item',
  templateUrl: './library-item.component.html',
  styleUrls: ['./library-item.component.scss'],
})
export class LibraryItemComponent implements OnInit {
  @HostListener('document:ionBackButton', ['$event'])
  @Input()
  courseName: any;
  @Input() videoTitle: any;
  @Input() channelName: any;
  @Input() thumbnailGUID: any;
  @Input() courseId: any;
  @Input() videoId: any;
  public unsubscribeBackEvent: any;
  thumbURLCourse: string;
  shouldBounce = true;
  dockedHeight = 224;
  distanceTop = 0;

  constructor(
    private route: Router,
    private bottomSheetService: BottomSheetService,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    this.thumbURLCourse = environment.thumbURLCourse;
  }
  goToOpenCourse(courseId) {
    const textToConvert = courseId.toString();
    const conversionOutput = CryptoJS.AES.encrypt(
      textToConvert.trim(),
      ''
    ).toString();
    this.route.navigate(['/course', conversionOutput]);
  }
  async PlayVideo(videoId) {
    const textToConvert = videoId.toString();
    const conversionOutput = CryptoJS.AES.encrypt(
      textToConvert.trim(),
      ''
    ).toString();

    const modal = await this.modalController.create({
      component: PlayVideoPage,
      componentProps: { id: videoId },
      animated: true,
      backdropDismiss: true,
      
    });

    modal.onDidDismiss().then((dataReturned) => {
      this.bottomSheetService.toggleBottomSheet(false);
    });
    return await modal.present().then((modal) => {
      this.bottomSheetService.toggleBottomSheet(true);
    });
  }
}
