import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit {
  errorMessages = [];
  messageTitle: string = '';
  messageDec: string;
  messageID: number;
  constructor(
    public modalController: ModalController,
    private navParams: NavParams
  ) {
    this.messageID = this.navParams.data.msgID;
    this.errorMessages = JSON.parse(localStorage.getItem('ErrorMessages'));
    for (var i = 0; i < this.errorMessages.length; i++) {
      if (this.errorMessages[i].Id === this.messageID) {
        this.messageTitle = this.errorMessages[i].ErrorType;
        this.messageDec = this.errorMessages[i].ErrMsg;
      }
    }
  }

  ngOnInit() {}
  retry() {
    this.modalController.dismiss();
  }
}
