import { AlertController } from '@ionic/angular';
import { MatDialog } from '@angular/material/dialog';
import { ErrorMessageComponent } from '../components/error-message/error-message.component';
import { ModalController } from '@ionic/angular';
import { Injectable } from "@angular/core";

@Injectable()
export class AlertMessage {
  dialogIsOpen: boolean = false;
  constructor(
    public alertController: AlertController,
    public dialog: MatDialog,
    public modalController: ModalController
  ) {}

  async LoginFail() {
    const alert = await this.alertController.create({
      header: 'Login Fail!',
      subHeader: 'Login Failed!Please try again later',
      message: 'Please Enter Valid Email or password!',
      buttons: ['Retry'],
      cssClass: 'myclass',
    });
    await alert.present();
  }
  async NetworkError() {
    const alert = await this.alertController.create({
      header: 'Loading Fail!',
      subHeader: 'Network error !Please try again later',
      message: 'Please check data connection!',
      buttons: ['Retry'],
      cssClass: 'myclass',
    });
    await alert.present();
  }
  async EmailAlreadyExist() {
    const alert = await this.alertController.create({
      header: 'Mail ID already exist!!',
      message: 'Please try with another mail ID!',
      buttons: ['Retry'],
      cssClass: 'myclass',
    });
    await alert.present();
  }
  async openDialog(msgID) {
    if (!this.dialogIsOpen) {
      const modal = await this.modalController.create({
        component: ErrorMessageComponent,
        componentProps: {
          msgID: msgID,
        },
      });

      modal.onDidDismiss().then((dataReturned) => {
        this.dialogIsOpen = false;
      });

      await modal.present().then((res) => (this.dialogIsOpen = true));
    }
  }
}
