import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-clip-video-tag',
  templateUrl: './clip-video-tag.component.html',
  styleUrls: ['./clip-video-tag.component.scss'],
})
export class ClipVideoTagComponent implements OnInit {
  @Input() videoURL;
  @Input() index;
  @Input() Ext;
  data: string = environment.clipURL;
  constructor() {}

  ngOnInit() {
    this.data = this.data + this.videoURL + this.Ext;
  }
}
