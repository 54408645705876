import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { SearchService } from 'src/core/services/search.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-course-list-item',
  templateUrl: './course-list-item.component.html',
  styleUrls: ['./course-list-item.component.scss'],
})
export class CourseListItemComponent implements OnInit {
  @Input() courseName;
  @Input() channelName;
  @Input() enrollCount;
  @Input() thumbNail;
  @Input() courseId;
  @Input() isPageCheck: boolean;
  insertSearchHistory = {};
  thumbURLCourse_1:string;
  constructor(private route: Router, private searchService: SearchService) {}

  ngOnInit() {
    this.thumbURLCourse_1=environment.thumbURLCourse_1
  }
  OpenCourseSearch(CourseId, isPageCheck) {
    const textToConvert = CourseId.toString();
    const conversionOutput = CryptoJS.AES.encrypt(
      textToConvert.trim(),
      ''
    ).toString();
    if (isPageCheck) {
      this.insertSearchHistory = {
        SearchId: 0,
        UserId: parseInt(localStorage.getItem('ID')),
        ChnlId: 0,
        CrlId: CourseId,
        VidId: 0,
        CatId: 0,
      };
      this.searchService
        .setSearchHistory(this.insertSearchHistory)
        .subscribe((res) => res);
    }
    this.route.navigate(['/course', conversionOutput]);
  }
}
