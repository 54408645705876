import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TabbarComponent } from './shared/components/tabbar/tabbar.component';
import { PlayVideoPage } from './modules/play-video/play-video/play-video.page';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { VideoTagComponent } from './modules/play-video/components/video-tag/video-tag.component';
import { VideoDetailsComponent } from './modules/play-video/components/video-details/video-details.component';
import { VideoListComponent } from './modules/play-video/components/video-list/video-list.component';
import { ChannelDetailsComponent } from './modules/play-video/components/channel-details/channel-details.component';
import { changeSubscriptionService } from './modules/play-video/change-subscription.service';
import { RequestInterceptor } from 'src/core/interceptor/RequestInterceptor';
import { ClipModule } from './modules/clip/clip.module';
import { AuthGuard } from 'src/core/guards/auth.guard';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { SharedModule } from './shared/shared.module';
import { LoaderInterceptor } from 'src/core/interceptor/loader.interceptor';
import { LoaderService } from 'src/core/services/loader.service';
import { Network } from '@ionic-native/network/ngx';
import { AlertMessage } from './shared/alert-controller/alert-message';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Device } from '@ionic-native/device/ngx';
import { ErrorHandlingService } from 'src/core/services/error-handling.service';
import { ErrorIntercept } from 'src/core/interceptor/error.interceptor';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { NavigationBar } from '@ionic-native/navigation-bar/ngx';
@NgModule({
  declarations: [
    AppComponent,
    TabbarComponent,
    PlayVideoPage,
    VideoTagComponent,
    VideoDetailsComponent,
    VideoListComponent,
    ChannelDetailsComponent,
  ],
  entryComponents: [PlayVideoPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ animated: true }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    MatBottomSheetModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    ClipModule,
    SharedModule,
    MatProgressSpinnerModule,
    SuperTabsModule.forRoot(),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    changeSubscriptionService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true,
    },
    AuthGuard,
    LoaderService,
    Network,
    AlertMessage,
    AndroidPermissions,
    ErrorHandlingService,
    Device,
    ScreenOrientation,
    NavigationBar,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
