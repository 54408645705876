import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BottomSheetService {
  private bottomSheetValue: BehaviorSubject<any> = new BehaviorSubject(false);
  currentBottomSheet = this.bottomSheetValue.asObservable();

  toggleBottomSheet(value) {
    this.bottomSheetValue.next(value);
  }
}