import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Course } from 'src/core/types';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent implements OnInit {
  @Input() course: Course;
  @Input() showEnrolls: boolean;
  thumbURLCourse:string;
  constructor(private route: Router) {}

  ngOnInit() {
    this.thumbURLCourse=environment.thumbURLCourse_1;
  }

  getEnrolled() {
    // TODO: Account for values larger than 9,999
    const views = parseInt(this.course.EnrollCnt);
    if (views > 999) {
      const viewString = views.toString();
      return viewString[0] + ',' + viewString.slice(1);
    }
    return views;
  }
  courseCleanUp(name) {
    return name.toLowerCase().replace(' ', '-');
  }
  channelCleanUp(courseName) {
    return courseName.toLowerCase().replace(' ', '-');
  }

  openCoursePage(CourseId) {
    const textToConvert = CourseId.toString();
    const conversionOutput = CryptoJS.AES.encrypt(
      textToConvert.trim(),
      ''
    ).toString();
    this.route.navigate(['/course', conversionOutput]);
  }
  encryptMode(CourseId) {
    const textToConvert = CourseId.toString();
    return CryptoJS.AES.encrypt(textToConvert.trim(), '').toString();
  }
}
